<template>
    <div class="container">
        <h1 class="mt-4">Wie benutze ich Discord?</h1>
        <hr>
        <p>
            Hier findest du eine Anleitung, wie man Discord benutzt.
            Es ist weder eine Installation noch das Anlegen eines Accounts nötig.
            Wenn du nach dem Festlegen deines Benutzers gefragt wirst,
            ob du Email und Passwort für das Erstellen eines Accounts angeben willst,
            einfach das Fenster weg klicken.
            <br>
            Möchtest du Discord trotzdem installieren, 
            kannst du das hier tun: 
            <a href="https://discord.com/download">
                https://discord.com/download
            </a>
        </p>
        <div class="d-flex flex-column">
            <b-card 
                class="mb-2 btn-info pb-0"
                :img-src="require('@/assets/manual/s1.png')"
                img-alt="Image"
                img-bottom
            >
                <template #header>
                    <h4 class="mb-0">Mit dem Server verbinden</h4>
                </template>
                <p>
                    Drücke auf das Symbol rechts unten. Danach auf "Connect".
                    Falls Discord bereits installiert ist, sollte es sich automatisch öffnen
                    andernfalls müsste sich die Browserversion öffnen.
                </p>
            </b-card>
            <b-card
                class="my-2 btn-info pb-0 shadow"
                :img-src="require('@/assets/manual/s2.png')"
                img-alt="Image"
                img-bottom
            >
                <template #header>
                    <h4 class="mb-0">Discord benutzen</h4>
                </template>
                <ul>
                    <li>Kanäle mit einem "#" Symbol eignen sich nur zum schreiben mit Personen</li>
                    <li>Kanäle mit einem "Lautsprecher" Symbol eignen sich nur zum reden</li>
                </ul>
                <p>
                    <b>Wie kann man einem Kanal beitreten?</b>
                    <br>
                    Ganz einfach. Klicke auf den gewünschten Kanal und du bist beigetreten.
                </p>
            </b-card>
            <b-card
                class="my-2 btn-info pb-0 shadow"
                :img-src="require('@/assets/manual/s3.png')"
                img-alt="Image"
                img-bottom
            >
                <template #header>
                    <h4 class="mb-0">Kanäle verlassen</h4>
                </template>
                <ul>
                    <li>Sprachkanal: Indem man links unten auf das Telefon mit dem "X" klickt.</li>
                    <li>Textkanal: Indem man einfach auf einen anderen Textkanal klickt.</li>
                </ul>
            </b-card>
            <b-card
                class="my-2 btn-info pb-0 shadow"
                :img-src="require('@/assets/manual/s4.png')"
                img-alt="Image"
                img-bottom
            >
                <template #header>
                    <h4 class="mb-0">Verschiedene Themengebiete</h4>
                </template>
                <p>
                    Wenn du in der linken Leiste etwas herumscrollst, siehst du unterschiedliche Themengebiete.
                    Wenn du dich für eins davon interessierst, trete einfach diesem Kanal bei.
                </p>
            </b-card>
            <b-card class="mt-2 btn-info pb-0 shadow">
                Falls du weitere Hilfe benötigst, schreib einfach in den Kanal "#hilfe"
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
export default {
    name: "DiscordManual",
    components:{ BCard }
}
</script>
